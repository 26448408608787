@import url("https://use.typekit.net/hga6uvy.css");

* {
  font-family: ff-speak-web, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1,
h1 *,
h2 {
  font-family: freude, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h3,
nav * {
  font-family: ff-speak-web, sans-serif;
  font-weight: 800;
  font-style: normal;
}
